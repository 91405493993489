var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm.$style.articlesCategory},[_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.addCategory},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(_vm.$options.ADDWINE_ADMIN_ROUTES.BLOG.CATEGORIES.CREATE)}}},[_vm._v(" Создать категорию ")])],1)]),_c('el-table',{attrs:{"data":_vm.articleCategories,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"name","label":"Название категории"}}),_c('el-table-column',{attrs:{"prop":"slug","label":"Slug"}}),_c('el-table-column',{attrs:{"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ActionButtons',{attrs:{"name":"trash","viewLink":_vm.getRoute({
              route: _vm.$options.ADDWINE_ROUTES.ARTICLES.CATEGORY,
              params: { id: scope.row.slug },
              site: _vm.$options.PROJECTS.ADDWINE,
            }),"edit-link":_vm.getRoute({
              route: _vm.$options.ADDWINE_ADMIN_ROUTES.BLOG.CATEGORIES.CATEGORY,
              params: { id: scope.row.id },
            })},on:{"delete":function($event){return _vm.removeCategory(scope.row)}}})]}}])})],1),_c('el-pagination',{class:_vm.$style.pagination,attrs:{"layout":"prev, pager, next","page-size":_vm.limit,"total":_vm.total,"current-page":_vm.page,"background":""},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"current-change":_vm.getList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }