<template>
  <section :class="$style.articlesCategory">
    <div :class="$style.wrapper">
      <div :class="$style.addCategory">
        <el-button
          type="primary"
          @click="
            $router.push($options.ADDWINE_ADMIN_ROUTES.BLOG.CATEGORIES.CREATE)
          "
        >
          Создать категорию
        </el-button>
      </div>
    </div>

    <el-table :data="articleCategories" stripe>
      <el-table-column prop="name" label="Название категории">
      </el-table-column>
      <el-table-column prop="slug" label="Slug"> </el-table-column>

      <el-table-column width="120">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :viewLink="
              getRoute({
                route: $options.ADDWINE_ROUTES.ARTICLES.CATEGORY,
                params: { id: scope.row.slug },
                site: $options.PROJECTS.ADDWINE,
              })
            "
            :edit-link="
              getRoute({
                route: $options.ADDWINE_ADMIN_ROUTES.BLOG.CATEGORIES.CATEGORY,
                params: { id: scope.row.id },
              })
            "
            @delete="removeCategory(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'

import {
  ADDWINE_ROUTES,
  getRoute,
  ADDWINE_ADMIN_ROUTES,
} from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'
import addQueryParamsAddwine from '@/mixins/addQueryParamsAddwine'

export default {
  mixins: [notifications, addQueryParamsAddwine(function(params) { this.getList(params); })],
  components: {
    ActionButtons,
  },
  ADDWINE_ROUTES,
  ADDWINE_ADMIN_ROUTES,
  PROJECTS,
  data() {
    return {
      articleCategories: [],
      limit: 20,
      page: 1,
      total: 10,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    initializeFromQuery() {
      this.page = parseInt(this.$route.query.page, 10) || 1
      this.articleCategories = this.$route.query.articleCategories || []
      this.limit = this.$route.query.limit || 20
    },
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
      }

      const { value, error } =
        await delivery.ContentServiceCore.ArticleCategoriesActions.getList(
          query,
        )
      this.updatedQueryParams(query);
      loading.close()

      if (error) {
        return
      }

      this.articleCategories = value.data
      this.total = value.meta.count
    },

    async removeCategory(data) {
      const isConfirm = confirm(`Вы точно хотите удалить: ${data.name}?`)
      if (isConfirm) {
        const res =
          await delivery.ContentServiceCore.ArticleCategoriesActions.delete(
            data.id,
          )
        if (!res.error) {
          this.showNotification('Категория успешно удалена', 'success')
          this.getList()
          return
        }
        this.showNotification('Ошибка удаления категории', 'error')
      }
    },
    getRoute({ route, params, site }) {
      return getRoute({ route, params, site })
    },
  },
}
</script>

<style lang="scss" module>
.articlesCategory {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
    .addCategory {
      display: flex;
      .addInput {
        width: 30rem;
        margin-right: 0.625rem;
      }
    }
  }

  .pagination {
    @include pagination;
  }
}
</style>
